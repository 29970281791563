<template>
  <div class="image-type-list">
    <en-table-layout
      :table-data="tableData.data"
      :loading="loading"
    >
      <template slot="toolbar">
        <div class="toolbar-title"></div>
        <el-form-item label="最后更新时间">
          <el-date-picker
            style="width: 220px"
            v-model="times"
            type="daterange"
            :editable="false"
            unlink-panels
            size="small"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            :picker-options="{ disabledDate(time) { return time.getTime() > Date.now() }, shortcuts: MixinPickerShortcuts }"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="Params.category_name" size="small" style="width: 180px;" placeholder="分类名称"></el-input>
        </el-form-item>
        <el-button type="primary" size="small" @click="GET_listCategory">搜索</el-button>
        <div class="col"></div>
        <el-button type="primary" size="small" @click="openDialog()">添加图片库</el-button>
      </template>

      <template slot="table-columns">
        <el-table-column label="编号" prop="sn" width="120"></el-table-column>
        <el-table-column label="分类名称" prop="category_name"></el-table-column>
        <el-table-column label="顺序" prop="sort" width="80"></el-table-column>
        <el-table-column label="最后更新时间" prop="update_time" :formatter="MixinUnixToDate" width="160"></el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="edit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination
        slot="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tableData.page_no"
        :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="tableData.data_total">
      </el-pagination>
    </en-table-layout>

    <x-dialog :proxy="editDialog">
      <el-form label-width="90px">
        <el-form-item label="图片库名称">
          <el-input v-model="addParams.category_name"></el-input>
        </el-form-item>
        <el-form-item label="图片库顺序">
          <el-input v-model="addParams.sort"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import * as API_imgSetting from '@/api/imgSetting'
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
import XDialog from "../../../components/x-dialog/x-dialog";
import {$xDialog} from "../../../components/x-dialog/dialog.proxy";
// import {Foundation} from '@/../ui-utils'

export default {
  name: 'imageTypeList',
  components: {XDialog, EnTableLayout},
  data() {
    return {
      editDialog: $xDialog.create({
        width: '400px',
        beforeConfirm: () => this.submit(),
      }),
      loading: false,
      dialogVisible: false,
      isEdit: false,
      times: "",
      addParams: {
        category_name: "",
        sort: "",
        id: "",
      },
      Params: {
        page_no: 1,
        page_size: 20,
        category_name: "",
        start_time: "",
        end_time: "",
      },
      tableData: {}
    };
  },
  mounted() {
    this.GET_listCategory();
  },
  methods: {
    POST_updateCategory() {
      API_imgSetting.updateCategory(this.addParams).then(res => {
        this.GET_listCategory();
        this.category_name = "";
        this.start_time = "";
        this.end_time = "";
        this.times = "";
      })
    },
    DEL_deleteCategory(id) {
      API_imgSetting.deleteCategory(id).then(res => {
        this.GET_listCategory();
      })
    },
    POST_addCategory() {
      return new Promise(resolve => {
        API_imgSetting.addCategory(this.addParams).then(res => {
          this.category_name = "";
          this.start_time = "";
          this.end_time = "";
          this.times = "";
          this.GET_listCategory().then(() => {
            resolve(true);
          });
        }).catch(err => {
          resolve(false);
        });
      });
    },
    GET_listCategory() {
      if (!!this.times) {
        this.Params.start_time = this.times[0]
        this.Params.end_time = this.times[1] + 1000 * 60 * 60 * 24;
      } else {
        this.Params.start_time = '';
        this.Params.end_time = '';
      }
      return API_imgSetting.listCategory(this.Params).then(res => {
        res.data.forEach((item, index) => {
          res.data[index].created_time = item.created_time / 1000;
          res.data[index].update_time = item.update_time / 1000;
        });
        this.tableData = res;
      })
    },
    edit(row) {
      this.openDialog(row);
    },
    del(row) {
      this.$confirm('确定要删除该分类吗？删除后，该分类下的图片也会一并删除', '删除操作', {type: 'warning'}).then(() => {
        console.log(row)
        this.DEL_deleteCategory(row.id);
      }).catch(() => {
      })
    },
    handleSizeChange(val) {
      this.Params.page_size = val;
      this.GET_listCategory();
    },
    handleCurrentChange(val) {
      this.Params.page_no = val;
      this.GET_listCategory();
    },
    openDialog(row) {
      this.isEdit = !!row;
      row = row || {};
      this.addParams.category_name = row.category_name;
      this.addParams.sort = row.sort;
      this.addParams.sn = row.sn;
      this.addParams.id = row.id;
      this.editDialog.display({title: this.isEdit ? '编辑图片库' : '添加图片库'});
    },
    submit() {
      if (!this.isEdit) {
        return this.POST_addCategory();
      } else {
        return this.POST_updateCategory();
      }
    },
  },
}
</script>

<style scoped>
::v-deep div.toolbar {
  padding: 20px;
}

.conditions {
  display: inline-block;
  margin-right: 20px;
}

.dialogConditions {
  margin-bottom: 20px;
}
</style>
